* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #f5f5f5;
}

.nav {
    text-decoration: none;
    background-color: white;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0rem 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: sticky;
    top: 0;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.nav ul{
    color: #000;
    margin: 0px 0px;
    list-style: none;
    display: flex;
    gap: 2rem;
    transition: padding 0.2s ease-out;
    font-size: 22px;
    text-decoration: none;
}

.nav a {
    text-decoration: none;
}

.nav li:hover {
    padding: 0.3rem;
    border-radius: 1rem;
    background-color: rgb(183, 183, 183);
}

.container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem;
    margin: 10px 20px;
}

.startDate {
    padding: 10px;
}

.endDate {
    padding: 10px;
    margin: 0px 20px;
}

.statButtons{
    display: flex;
    justify-content: space-between;
    padding: 20px 200px;
    background-color: rgb(212, 16, 64);
    border-radius: 12px;
}