input[type=range] {
    height: 27px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background-color: #f5f5f5;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 2px #50555C;
    background: #D41040;
    border-radius: 14px;
    border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 0px solid #000000;
    height: 20px;
    width: 41px;
    border-radius: 14px;
    background: #5B0035;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #D41040;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 2px #50555C;
    background: #D41040;
    border-radius: 14px;
    border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 0px solid #000000;
    height: 20px;
    width: 41px;
    border-radius: 14px;
    background: #5B0035;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #D41040;
    border: 0px solid #000000;
    border-radius: 28px;
    box-shadow: 1px 1px 2px #50555C;
}
input[type=range]::-ms-fill-upper {
    background: #D41040;
    border: 0px solid #000000;
    border-radius: 28px;
    box-shadow: 1px 1px 2px #50555C;
}
input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 0px solid #000000;
    height: 20px;
    width: 41px;
    border-radius: 14px;
    background: #5B0035;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #D41040;
}
input[type=range]:focus::-ms-fill-upper {
    background: #D41040;
}